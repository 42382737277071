<template>
  <div>
    <b-form-group label="Référence">
      <b-input-group class="input-group-merge">
        <b-input-group-prepend is-text>
          <feather-icon icon="MaximizeIcon" />
        </b-input-group-prepend>
        <b-form-input
          ref="select"
          v-model="ref"
          placeholder="Référence de colis"
        />
      </b-input-group>
    </b-form-group>

    <b-button
      variant="primary"
      type="submit"
      @click.prevent="searchOrder"
    >
      Chercher colis
    </b-button>
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,

  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {

    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,

    BButton,

  },
  directives: {
    Ripple,
  },
  data() {
    return {

      ref: '',
    }
  },

  mounted() {
    this.$refs.select.focus()
  },
  methods: {

    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },

    async searchOrder() {
      this.$router.push({ name: 'order-echange-show', params: { id: this.ref } })
    },

  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
